import { useContext, createContext, useEffect } from 'react';
import { getCurrentUser } from '@core/modules/acl/services';
import { withTranslation } from '@i18n';
import { withApollo } from '@lib_apollo';

const AclProvider = ({ children }) => {
    const [doGetCurrenrUser, { data, error, loading }] = getCurrentUser();
    const { resources = [] } = (data && !error && !loading) ? data.getCurrentUser : {};

    const isAble = (id, action) => {
        const findResource = resources.find((el) => el.id === id);
        if (!findResource) return false;
        const findAction = findResource.actions.find((el) => el === action);
        if (findAction) return true;
        return false;
    };
    const value = {
        isAble,
        resources,
    };

    useEffect(() => {
        window.user = data?.getCurrentUser;
    }, [data]);

    useEffect(() => {
        doGetCurrenrUser();
    }, []);
    return <AclContext.Provider value={value}>{children}</AclContext.Provider>;
};

export default withApollo({ ssr: false })(withTranslation()(AclProvider));
export const AclContext = createContext({});
export const useAclContext = () => useContext(AclContext);
