import { useLazyQuery } from '@apollo/client';
import * as Schema from '@core/modules/acl/services/schema';

const context = {
    context: { request: 'internal' },
};

const fetchPolicy = {
    fetchPolicy: 'cache-and-network',
};

export const getCurrentUser = (options) => useLazyQuery(Schema.getCurrentUser, {
    ...options, ...context, ...fetchPolicy,
});

export default {
    getCurrentUser,
};
